body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: $mr;
  color: $text-primary;
}

.glyphicon {
  font-weight: bold;
  top: -10px;

}

.section-header {
  .section-title {
    margin-bottom: ($grid-gutter-width);
    font-size: 52px;
    font-family: $ml;
    text-align: center;
    color: $dark-blue;
    &.section-title-underline {
      &:after {
        top: 60px;
      }
    }
  }

  .section-title-underline {
    position: relative;
    &:after {
      content: '';
      width: 70px;
      border-bottom: 2px solid $light-blue;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: ($grid-gutter-width * 3);
    }
  }

  .section-subtitle {
    text-align: center;
    font-weight: 500;
    margin-bottom: 40px;
    line-height: 25px;
  }
}



@media (max-width: $screen-xs-max) {
  section {
    padding: ($grid-gutter-width * 1.5) 0;
  }

  .section-header {
    .section-title {
      &.section-title-underline {
        &:after {
          top: 60px;
        }
      }
    }
  }

}
