.navbar-default {

  -webkit-box-shadow: 0 8px 6px -6px #999;
  -moz-box-shadow: 0 8px 6px -6px #999;
  box-shadow: 0 8px 6px -6px #999;
  background-color: white;
  height: 120px;
  .navbar-nav {
    padding-top: 40px;
    padding-left: 20px;
    .active {
      a {
        background-color: transparent;
        color: $dark-blue;
        &:hover {
          background-color: transparent;
          color: $dark-blue;
        }
        &:focus {
          background-color: transparent;
          color: $dark-blue;
        }
      }
    }
    li {
      a {
        color: $light-blue;
        font-family: $kr;
        font-size: 140%;
        position: relative;
        display: inline-block;
        text-decoration: none;

        &:after {
          border-bottom: 2px solid $dark-blue;
          content: "";
          display: block;
          margin: 0.25em auto 0;
          transition: width 250ms ease-in-out 0s;
          width: 0;
        }
        &:hover {
          color: $light-blue;
          &:after {
            transition: width 300ms ease-in-out 0s;
            width: 70px;
          }
        }
        &:focus {
          color: $dark-blue;
        }
      }
    }
  }
}

@media (max-width: $screen-sm-max) {

  .navbar-brand {
    img {
      max-width: 75%;
    }
  }

.navbar-default {
  height: 95px;
  ul {
    background-color: white;
  }
  .navbar-toggle {
    margin-top: 30px;
  }
  .navbar-nav {
    padding: 0px;
    margin: 0px -15px;
  }
}
.navbar-collapse {
  text-align: center;
  .navbar-right {
    margin-top: -10px;
    padding-bottom: 20px;
    text-align: center;
  }
}

}
