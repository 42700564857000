/** Fonts **/

// Khula fonts
@font-face {
  font-family: "Khula Regular";
  src: url(../../assets/fonts/Khula-Regular.ttf);
}

@font-face {
  font-family: "Khula Bold";
  src:url(../../assets/fonts/Khula-Bold.ttf);
}

@font-face {
  font-family: "Khula Light";
  src:url(../../assets/fonts/Khula-Light.ttf);
}

@font-face {
  font-family: "Khula Extra Bold";
  src:url(../../assets/fonts/Khula-ExtraBold.ttf);
}

@font-face {
  font-family: "Khula Semi Bold";
  src:url(../../assets/fonts/Khula-SemiBold.ttf);
}

// Lato Fonts
@font-face {
  font-family: "Lato Light";
  src: url(../../assets/fonts/Lato-Light.ttf);
}

@font-face {
  font-family: "Lato Regular";
  src: url(../../assets/fonts/Lato-Regular.ttf);
}

@font-face {
  font-family: "Lato Bold";
  src: url(../../assets/fonts/Lato-Bold.ttf);
}

// Montserrat Fonts
@font-face {
  font-family: "Montserrat Regular";
  src: url(../../assets/fonts/Montserrat-Regular.ttf);
}

@font-face {
  font-family: "Montserrat Bold";
  src: url(../../assets/fonts/Montserrat-Bold.ttf);
}

@font-face {
  font-family: "Montserrat Light";
  src: url(../../assets/fonts/Montserrat-Light.ttf);
}

@font-face {
  font-family: "Montserrat Thin";
  src: url(../../assets/fonts/Montserrat-Thin.ttf);
}

@font-face {
  font-family: "Montserrat Italic";
  src: url(../../assets/fonts/Montserrat-Italic.ttf);
}

@font-face {
  font-family: "Montserrat Medium";
  src: url(../../assets/fonts/Montserrat-Medium.ttf);
}

@font-face {
  font-family: "Rubik Black";
  src: url(../../assets/fonts/Rubik-Black.ttf);
}

@font-face {
  font-family: "Rubik Regular";
  src: url(../../assets/fonts/Rubik-Regular.ttf);
}

@font-face {
  font-family: "Rubik Bold";
  src: url(../../assets/fonts/Rubik-Bold.ttf);
}

@font-face {
  font-family: "Rubik Italic";
  src: url(../../assets/fonts/Rubik-Italic.ttf);
}

@font-face {
  font-family: "Rubik Light";
  src: url(../../assets/fonts/Rubik-Light.ttf);
}

@font-face {
  font-family: "Rubik Medium";
  src: url(../../assets/fonts/Rubik-Medium.ttf);
}
/*********************
*       Icons        *
**********************/
@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?ywzdhw');
  src:  url('../fonts/icomoon.eot?ywzdhw#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?ywzdhw') format('truetype'),
    url('../fonts/icomoon.woff?ywzdhw') format('woff'),
    url('../fonts/icomoon.svg?ywzdhw#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon--:before {
  content: "\e900";
  color: #52aadf;
}
.icon-+:before {
  content: "\e901";
  color: #52aadf;
}
.icon-arrow-slick:before {
  content: "\e902";
  color: #fff;
}
.icon-email:before {
  content: "\e903";
  color: #003b5a;
  font-size: 10px;
}
.icon-face:before {
  content: "\e904";
  color: #003b5a;
}
.icon-insta:before {
  content: "\e905";
  color: #003b5a;
}
.icon-tel:before {
  content: "\e906";
  color: #003b5a;
}
.icon-venda:before {
  content: "\e907";
  color: #003b5a;
}
.icon-negociacao:before {
  content: "\e908";
  color: #00628c;
}
.icon-antecipacao:before {
  content: "\e909";
  color: #1d97c6;
}
.icon-pagamento:before {
  content: "\e90a";
  color: #379c9a;
}
.icon-Path-1:before {
  content: "\e90b";
  color: #003b5a;
}
.icon-arrow:before {
  content: "\e90c";
  color: #fff;
}
.icon-icon1:before {
  content: "\e90d";
  color: #fff;
}
.icon-icon2:before {
  content: "\e90e";
  color: #fff;
}
.icon-icon3:before {
  content: "\e90f";
  color: #fff;
}
.icon-icon4:before {
  content: "\e910";
  color: #fff;
}
