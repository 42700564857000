footer {
  font-family: $mr;
  font-size: 15px;
  color: $text-primary;
  background-color: white;
  padding-top: 15px;

  .rodape {
    padding-bottom: 15px;
  }

  a {
    color: $dark-blue;
    padding-bottom: 10px;
    font-weight: bold;
    &:hover {
      text-decoration: none;
    }
  }

  .endereco {
    //padding-bottom: 15px;
  }

  .more-less {
    float: right;
    font-size: 35px;
  }

  .underline-footer {
    position: relative;
    &:after {
      content: '';
      width: 100%;
      border-bottom: 1px solid $light-blue;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 25px;
    }
  }
  .redes-sociais {
    text-decoration: none;
    font-family: $mr;
    a {
      color: $text-primary;
      &:hover {
        color: $light-blue;
        text-decoration: none;
      }
    }
  }

  h3 {
    font-size: 17px;
    font-family: $mb;
    color: $dark-blue;
    margin-bottom: 15px;
  }

  .contato {
    .tel-number {
      font-size: 20px;
    }
  }

}

.tecnologia {
  height: 60px;
  padding: 15px;
  background-color: $light-gray;
  font-family: $mr;
  font-size: 15px;
  color: #afb6bc;

  .copyright {
    margin-top: 5px;
  }
  .resultate-logo {
    font-family: $mb;
  }
}



@media (max-width: $screen-xs-max) {
  footer {
    .endereco {
      .map {
        width: 150px;
      }
    }


  }
  .tecnologia {
    text-align: center;
    height: 100px;
    .resultate-logo {
      text-align: center;
      line-height: 50px;
    }
  }
}
