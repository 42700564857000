#cadastro {
  background-color: $form-blue;
  padding: ($grid-gutter-width * 5) 0;
  padding-bottom: 75px;
  .section-header {
    .section-title {
      color: white;
    }
    .section-subtitle {
      color: white;
      text-align: center;
      margin: 0 auto;
      max-width: 65%;
    }
  }
  .form-wrapper {
    max-width: 100%;
   form {

     h4 {
       color: white;
       padding: 15px;
     }

     .form-group {
       &:last-child {
         margin-bottom: $grid-gutter-width;
       }
     }
     .form-label {
     	font-size: 12px;
      font-weight: bold;
     	color: white;
     	margin: 0;
     	display: block;
     	opacity: 1;
     	-webkit-transition: .333s ease top, .333s ease opacity;
     	transition: .333s ease top, .333s ease opacity;
     }
     .form-control {
      padding: 0px 0px;
      height: 25px;
     	border-radius: 0;
     	border-color: $light-blue;
      border-width: 0 0 1px 0;
      border-style: none none solid none;
      box-shadow: none;
     }
     .form-control:focus {
     	box-shadow: none;
     	border-color: white;
     }
     .js-hide-label {
     	opacity: 0;
     }
     .js-unhighlight-label {
     	color: $light-green;
     }

     select {
       background-color: $dark-blue;
       color: white;
       font-size: 13px;
     }
     .datalist {
       border-color: $light-blue;
       border-width: 0 0 1px 0;
       border-style: none none solid none;
       width: 100%;
       height: 25px;
     }
     input {
       background-color: transparent;
       color: white;
       font-size: 13px;
     }
     ::-webkit-input-placeholder {
       color: white;
     }
     ::-moz-input-placeholder {
       color: white;
     }
     input[type="submit"] {
       color: #fff;
       background-color: $light-green;
       font-size: 17px;
       display: block;
       margin: 0 auto;
       
       &:hover {
         background-color: $dark-green;
       }
     }



     textarea {
       background-color: transparent;
       color: white;
       font-size: 13px;
       height: 50px;
     }
    .checks {
      line-height: 75px;
      margin-bottom: -30px;
    }
    .form-checkbox {
      color: white;
      font-weight: normal;
     }

   }
  }
  .btn-wfr-green {
    max-width: 100%;
    width: 300px;
    height: 50px;
    font-size: 20px;
  }
}

#message-cad {
  display: none;
  background-color: $dark-blue;
  background-size: 100% 100%;
  padding: ($grid-gutter-width * 2) 0;
  margin-top: 100px;
  height: 500px;
  .section-header {

    .circle-ok {
      width: 120px;
      height: 120px;
      background-color: transparent;
      border: 4px solid $light-green;
      border-radius: 100%;
      margin-left: auto;
      margin-right: auto;
      img {
        margin-left: auto;
        margin-right: auto;
        margin-top: 30px;
        display: block;
        border: green;
      }
    }
    .section-title {
      color: white;
      width: 50%;
      margin: auto;
      padding-top: 50px;
      &.section-title-underline {
        &:after {
          top: 200px;
        }
      }
    }
    .section-subtitle {
      color: white;
      text-align: center;
      margin: 0 auto;
      max-width: 65%;
      h4 {
        padding-top: 10%;
      }
    }
  }
}


@media (max-width: $screen-xs-max) {
  #cadastro {
    .section-header {
      .section-title {
        &.section-title-underline {
          &:after {
            top: 120px;
          }
        }
      }
    }
    .form-wrapper {
      form {
        .checks {
          line-height: 15px;
        }
        .submit-btn {
          margin-top: 40px;
        }
      }
    }
  }

  #message-cad {
    .section-header {
      .section-title {
        font-size: 40px;
        &.section-title-underline {
          &:after {
            top: 240px;
          }
        }
      }
    }
  }
}
