.col {
  float: left;
}

.right-col, .left-col {
  width: 25%;
}

.center-col {
  width: 50%;
}
