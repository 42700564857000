
.banner {
  //height: calc(100vh - 110px);
  position: relative;
  .item {
    max-width: 100%;
    height: auto;
    width: 1920px;
    background-position: center center;
    //height: calc(100vh - 110px);
    outline: none;
    img {
      margin-top: 120px;
      max-width: 100%;
    }
  }

  .slick-arrow {
    z-index: 2;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 100%;
    background-color: rgba(159, 210, 234, 0.5);
    width: 70px;
    height: 70px;
    &:hover {
      background-color:#75BDE2;
      text-decoration: none;
    }
    &.slick-next {
      right: 14%;
      text-decoration: none;
    }
    &.slick-prev {
      left: 15%;
      text-decoration: none;
      transform: rotate(180deg);
      margin: -35px;
    }
    .icon-arrow-slick {
      &:before {
        padding: 25px;
        line-height: 75px;
      }
    }
    span {
      font-size: 48px;
      color: #fff;

    }
  }
}


@media( max-width: $screen-xs-max ) {
  .banner {
    .slick-arrow {
      top: 50%;
      &.slick-prev {
        left: 12%;
      }
      &.slick-next {
        right: 1%;

      }
    }
  }
}
