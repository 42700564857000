.banner {
  .banner-mobile-slider {
    display: none;
  }
}

#como-funciona {
  background-color: $light-gray;
  padding-bottom: $grid-gutter-width * 6;
  .section-header {
    .section-subtitle {
      margin-bottom: 0px;
    }
  }
  .wrapp {
    max-width: 60%;
    text-align: center;
  }
  .item {
    margin-top: 100px;
  }
  .item-title {
    text-transform: uppercase;
    font-family: $rb;
  }
  .venda {
    color: $dark-blue;
  }
  .negociacao {
    color: $medium-blue;
  }
  .antecipacao {
    color: $light-blue;
  }
  .pagamento {
    color: $special-blue;
  }
  p {
    font-size: 17px;
    min-height: 120px;
    color: $text-primary;
  }
  .slick-slide {
    img {
      margin: auto;
    }
  }
}


.box-azul {
  margin-top: -160px;

  .saibamais {
    background-color: $light-blue;
    padding: 0 80px 30px;
    width: 70%;
    margin: auto 15%;

    .seta {
      width: 0;
      height: 0;
      border-left: 30px solid transparent;
      border-right: 30px solid transparent;
      border-top: 30px solid $light-gray;
      margin: auto;
      margin-top: -10px;
    }

    h2 {
      color: white;
      font-family: $ml;
    }
    ul {
      li {
      color: white;
      }
    }
  }
}

#faq {
  margin-bottom: 50px;
  .section-header {
    .section-title {
      margin-top: 50px;
    }
  }
  .panel-title  {
    font-size: 17px;
    font-family: $ml;
  }
  .panel-default {
    border-radius: 0;
    .panel-heading {
      color: $light-blue;
      background-color: white;
      text-align: center;
      padding: 20px;
    }
    .panel-body {
      text-align: center;
      font-size: 15px;
      padding: 30px;
    }
  }
  .right-arrow {
    float: right;
    font-size: 35px;
    cursor: pointer;
    vertical-align: middle;
  }
  .pull-right {
    font-size: 35px;
    cursor: pointer;
    .glyphicon {
      vertical-align: middle;
    }
  }
}

#parceiros {
  background-color: $light-gray;
  padding-bottom: 20px;

  .parceiros-slider {
    .item {
      align-items: center;
      outline: none;
    }
  }

}

#contact {
  background-image: url(../../dist/images/contato.png);
  background-size: 100% 100%;
  padding: ($grid-gutter-width) 0;
  .section-header {
    .section-title {
      color: white;
    }
    .section-subtitle {
      color: white;
      text-align: center;
      margin: 0 auto;
      max-width: 65%;
    }
  }
  .form-wrapper {
    max-width: 65%;
    margin: 0 auto;
   form {
     .form-group {
       &:last-child {
         margin-bottom: $grid-gutter-width;
       }
     }
     .form-label {
     	font-size: 12px;
      font-weight: bold;
     	color: white;
     	margin: 0;
     	display: block;
     	opacity: 1;
     	-webkit-transition: .333s ease top, .333s ease opacity;
     	transition: .333s ease top, .333s ease opacity;
     }
     .form-control {
      padding: 0px 0px;
      height: 25px;
     	border-radius: 0;
     	border-color: $light-blue;
      border-width: 0 0 1px 0;
      border-style: none none solid none;
      box-shadow: none;
     }
     .form-control:focus {
     	box-shadow: none;
     	border-color: white;
     }
     .js-hide-label {
     	opacity: 0;
     }
     .js-unhighlight-label {
     	color: $light-green;
     }

     input {
       background-color: transparent;
       color: white;
       font-size: 13px;
     }
     ::-webkit-input-placeholder {
       color: white;
     }
     ::-moz-input-placeholder {
       color: white;
     }
     input[type="submit"] {
       color: #fff;
       font-size: 17px;
       background-color: $light-green;
       &:hover {
         background-color: $dark-green;
       }
     }

     textarea {
       resize: none;
       background-color: transparent;
       color: white;
       font-size: 13px;
       height: 50px;
     }
   }
  }
  .btn-wfr-green {
    max-width: 100%;
    width: 750px;
    height: 50px;
    font-size: 20px;
  }
}

#message {
  display: none;
  background-image: url(../../dist/images/contato.png);
  background-size: 100% 100%;
  padding: ($grid-gutter-width * 2) 0;
  height: 500px;
  .section-header {

    .circle-ok {
      width: 120px;
      height: 120px;
      background-color: transparent;
      border: 4px solid $light-green;
      border-radius: 100%;
      margin-left: auto;
      margin-right: auto;
      img {
        margin-left: auto;
        margin-right: auto;
        margin-top: 30px;
        display: block;
        border: green;
      }
    }
    .section-title {
      color: white;
      width: 50%;
      margin: auto;
      padding-top: 50px;
      &.section-title-underline {
        &:after {
          top: 200px;
        }
      }
    }
    .section-subtitle {
      color: white;
      text-align: center;
      margin: 0 auto;
      max-width: 65%;
      h4 {
        padding-top: 10%;
      }
    }
  }
}

#wfr {

  .wfr-slider {
    background-image: url(../../dist/images/bg.png);
    background-repeat: no-repeat;

    .item {
      text-align: center;
      outline: none;
      h4 {
        border-radius: 100%;
        background: $light-blue; /* For browsers that do not support gradients */
        background: -webkit-linear-gradient($light-blue, #8AB5CF); /* For Safari 5.1 to 6.0 */
        background: -o-linear-gradient($light-blue, #8AB5CF); /* For Opera 11.1 to 12.0 */
        background: -moz-linear-gradient($light-blue, #8AB5CF); /* For Firefox 3.6 to 15 */
        background: linear-gradient($light-blue, #8AB5CF); /* Standard syntax (must be last) */
        display: inline-block;
        width: 190px;
        height: 190px;
        line-height: 220px;
        border-radius: 100%;
        color: white;
        font-size: 85px;
        margin-bottom: 5px;
      }
      p {
        font-size: 18px;
        padding: 20px;
        color: $light-blue;
      }
    }
  }
}


// Mobile
@media (max-width: $screen-xs-max) {

  .banner {
    .banner-slider {
      display: none;
    }
    .banner-mobile-slider {
      display: block;
    }
    .item {
      img {
        margin-top: 95px;
      }
    }
  }

  #wfr {
    .wfr-slider {
      .item {
        padding: 0;
        text-align: center;
      }
    }
    .section-header {
      .section-title {
        &.section-title-underline {
          &:after {
            top: 180px;
          }
        }
      }
    }
  }

  #como-funciona {
    .section-header {
      .section-title {
        &.section-title-underline {
          &:after {
            top: 120px;
          }
        }
      }
    }
  }

  .box-azul {

    .saibamais {
      padding: 25px;

      .seta {
        margin-top: -25px;
      }
    }
  }

  #faq {
    .section-header {
      .section-title {
        &.section-title-underline {
          &:after {
            top: 120px;
          }
        }
      }
    }
    .glyphicon {
      top: -1px;
    }
  }

  #parceiros {
    .parceiros-slider {
      .item {
        align-content: center;
        padding: 0;
        img {
          margin-left: 50px;
        }
      }
    }
  }

  #contact {
    .section-header {
      .section-title {
        &.section-title-underline {
          &:after {
            top: 180px;
          }
        }
      }
    }
  }

  #message {
    .section-header {
      .section-title {
        font-size: 40px;
        &.section-title-underline {
          &:after {
            top: 240px;
          }
        }
      }
    }
  }

}
