// Colors
$light-blue: #5FAEDD;
$medium-blue: #01628C;
$dark-blue: #053B58;
$special-blue: #389C99;
$form-blue: #003B5A;

$light-green: #78AF1E;
$dark-green: #009045;

$text-primary: #9C9FA0;
$light-gray: #F9F9F9;

$brand-primary:  #000000;
$primary-green:  #27ae60;
$primary-blue: #25247b;


// Fonts
$kr: 'Khula Regular';
$kb: 'Khula Bold';
$keb: 'Khula Extra Bold';
$ksb: 'Khula Semi Bold';
$kl: 'Khula Light';

$mr: 'Montserrat Regular';
$mb: 'Montserrat Bold';
$ml: 'Montserrat Light';
$mm: 'Montserrat Medium';
$mt: 'Montserrat Thin';
$mi: 'Montserrat Italic';

$rr: 'Rubik Regular';
$rb: 'Rubik Bold';
$rl: 'Rubik Light';
$ri: 'Rubik Italic';
$rm: 'Rubik Medium';
$rbla: 'Rubik Black';


// Buttons

/************
* Bootstrap *
 ************/

// Buttons
$btn-border-radius-large: 0;

$btn-default-bg: $light-gray;
$btn-default-border: $light-gray;
$btn-default-color: #3e5564;

$btn-primary-bg: $primary-green;
$btn-primary-color: #fff;





// Colors
$brand-primary:  #005b77;
$yellow: #f7dd30;
$green: #3da603;
$gray: #d4d4d4;
$blue: #3866af;
$red: #ff0000;

// Fonts
$gl: Gotham Light;
$gm: Gotham Medium;
$gb: Gotham Bold;

// Buttons

/************
* Bootstrap *
 ************/

// Forms
$input-border: $brand-primary;
$input-border-radius: 0;
$input-height-base: 54px;
$input-color: darken($gray, 40%);

// Buttons
$btn-success-bg: $green;
$btn-success-border: darken($btn-success-bg, 0);
$btn-border-radius-base: 0;
$btn-border-radius-large: 0;

// Input group
$input-group-addon-bg: $brand-primary;
$input-group-addon-border-color: $brand-primary;

// Alerts
$alert-success-bg: $green;
$alert-success-text: #fff;
$alert-success-border: $green;

$alert-danger-bg: $red;
$alert-danger-text: #fff;
$alert-danger-border: $red;
