.btn-lg {
padding: 15px 20px;
}

.btn {
  padding: 10px 25px;
  border-radius: 30px;
  background-color: $light-green;
  font-family: $mb;
  font-size: 15px;
  transition-duration: 0.3s;
  border: transparent;

  &:hover {
    background-color: $dark-green;

  }
}

.btn-default {
  color: $light-green;
  padding: 10px 40px;
  background-color: transparent;
  border: 1px solid $light-green;
  transition-duration: 0.3s;
  &:hover {
    border-color: $dark-green;
    color: $dark-green;
    background-color: transparent;
  }
}

.btn-wfr-green {
  background-color: $light-green;
  color: white;
  border-radius: 30px;
  border: none;
  font-family: $mb;
  font-size: 15px;
  height: 45px;
  width: 120px;
  transition-duration: 0.3s;

  &:hover {
    background-color: $dark-green;
  }
  &:active {
    padding: 1em;
    cursor: pointer;
    background: $light-green;
    text-decoration: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-font-smoothing: antialiased;
  }
}

.btn-wfr-trans {
  background: 0 0 transparent;
  color: $light-green;
  border-radius: 30px;
  border: 1px solid $light-green;
  color: $light-green;
  font-family: $mb;
  text-align: center;
  font-size: 15px;
  height: 45px;
  width: 120px;
  transition-duration: 0.3s;
  &:hover {
    border-color: $dark-green;
    color: $dark-green;
  }
  &:active {
    cursor: pointer;
    background: $light-green;
    color: white;
    border: transparent;
    text-decoration: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-font-smoothing: antialiased;
  }
}

.btn-top {
  z-index: 99;
  position: fixed;
  width: 59px;
  height: 59px;
  line-height: 59px;
  border-radius: 100%;
  background-color: $light-blue;
  right: 20px;
  bottom: 45px;
  text-align: center;
  transition-duration: 0.3s;

  &:hover {
    background-color: $dark-blue;
    text-decoration: none;

  }
  span {
    color: white;
    font-size: 25px;
    line-height: 60px;
  }
}

.btn-wfr-tips {
  border-radius: 100%;
  border: none;
  background-color: $light-blue;
  color: white;
  font-weight: bold;
  font-size: 10px;
  padding: 3px;
  line-height: 5px;
}
