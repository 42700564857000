.slick-slider {
  .slick-dots {
    padding: 0;
    display: block;
    margin: ($grid-gutter-width / 2) auto 0;
    text-align: center;
    li {
      list-style: none;
      display: inline-block;
      padding: ($grid-gutter-width / 6);
      &.slick-active {
        button {
          background: radial-gradient(circle, $blue 50%, #fff 65%);
          border: 1px solid $gray;
        }
      }
      button {
        outline: none;
        border-radius: 50%;
        border: 1px solid $gray;
        background-color: #fff;
        font-size: 0;
        width: 18px;
        height: 18px;
      }
    }
  }
}
